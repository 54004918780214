/*jslint node:true */

var xml2js = require('./xml2js');
var xml2json = require('./xml2json');
var js2xml = require('./js2xml');
var json2xml = require('./json2xml');

module.exports = {
  xml2js: xml2js,
  xml2json: xml2json,
  js2xml: js2xml,
  json2xml: json2xml
};
