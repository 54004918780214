Prism.languages.nix = {
	'comment': {
		pattern: /\/\*[\s\S]*?\*\/|#.*/,
		greedy: true
	},
	'string': {
		pattern: /"(?:[^"\\]|\\[\s\S])*"|''(?:(?!'')[\s\S]|''(?:'|\\|\$\{))*''/,
		greedy: true,
		inside: {
			'interpolation': {
				// The lookbehind ensures the ${} is not preceded by \ or ''
				pattern: /(^|(?:^|(?!'').)[^\\])\$\{(?:[^{}]|\{[^}]*\})*\}/,
				lookbehind: true,
				inside: null // see below
			}
		}
	},
	'url': [
		/\b(?:[a-z]{3,7}:\/\/)[\w\-+%~\/.:#=?&]+/,
		{
			pattern: /([^\/])(?:[\w\-+%~.:#=?&]*(?!\/\/)[\w\-+%~\/.:#=?&])?(?!\/\/)\/[\w\-+%~\/.:#=?&]*/,
			lookbehind: true
		}
	],
	'antiquotation': {
		pattern: /\$(?=\{)/,
		alias: 'important'
	},
	'number': /\b\d+\b/,
	'keyword': /\b(?:assert|builtins|else|if|in|inherit|let|null|or|then|with)\b/,
	'function': /\b(?:abort|add|all|any|attrNames|attrValues|baseNameOf|compareVersions|concatLists|currentSystem|deepSeq|derivation|dirOf|div|elem(?:At)?|fetch(?:Tarball|url)|filter(?:Source)?|fromJSON|genList|getAttr|getEnv|hasAttr|hashString|head|import|intersectAttrs|is(?:Attrs|Bool|Function|Int|List|Null|String)|length|lessThan|listToAttrs|map|mul|parseDrvName|pathExists|read(?:Dir|File)|removeAttrs|replaceStrings|seq|sort|stringLength|sub(?:string)?|tail|throw|to(?:File|JSON|Path|String|XML)|trace|typeOf)\b|\bfoldl'\B/,
	'boolean': /\b(?:false|true)\b/,
	'operator': /[=!<>]=?|\+\+?|\|\||&&|\/\/|->?|[?@]/,
	'punctuation': /[{}()[\].,:;]/
};

Prism.languages.nix.string.inside.interpolation.inside = Prism.languages.nix;
